import React, { useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";



function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);

  }, []);


  return (
    <div>

      {
        loading ?
          <div className=" h-screen flex justify-center items-center bg-blend-overlay">
            <PuffLoader
              className="bg-or"
              color={"white"}
              loading={loading}
              size={100}

            />
          </div>
          :
          <div className="h-screen flex justify-center items-center  bg-[#242f65] bg-blend-overlay bg-background  bg-cover overflow-hidden">
            <div className="h-screen flex justify-center items-center ">
              <div className="grid grid-cols-1 gap-y-8 items-center">
                <div>
                  <img className="inline-flex items-center w-[534px] mb-10" src="./logo.png" alt="logo" />
                  <p className="md:text-7xl text-5xl font-black text-center uppercase text-white">Binnenkort</p>
                  <p className="text-center md:text-3xl text-1xl text-white m-0">Vieren we de lancering van onze website.</p>
                </div>
                <div className="grid grid-rows-2  items-center " >
                  <p className="text-center text-white ">Voor al je vragen kan u ons steeds contacteren op <a className="" href="mailto:hello@dgitaal.be?subject=Ik heb een vraag over Dgitaal&body=Ik heb een vraag of opmerkingen, namelijk:">Hello@dgitaal.be</a></p>
                </div>
              </div>

            </div>


          </div>

      }

    </div>
  );

}

export default App;
